import './FullscreenLoader.css';

import { Spin } from 'antd';
import React from 'react';

const FullscreenLoader = React.memo(() => (
  <Spin spinning size="large">
    <div className="ssmm-fillscreen-loader" />
  </Spin>
));

export default FullscreenLoader;
