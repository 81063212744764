import 'firebase/functions';

import firebase from 'firebase/app';

const saveReviews = reviews => ({
  type: 'REVIEWS_RES',
  reviews,
});

export const loadReviews = () => async dispatch => {
  const getReviews = firebase.functions().httpsCallable('getReviews');

  const result = await getReviews({
    offset: 0,
    limit: 1000000,
    extension: 'ssmm',
  });

  const reviews = result.data;

  dispatch(saveReviews(reviews));
};
