import 'firebase/database';
import 'firebase/firestore';
import './index.css';

import firebase from 'firebase/app';
import React from 'react';
import ReactDOM from 'react-dom';

import config from './config/firebase';
import AppCont from './containers/AppCont';
import store from './redux/store';
import * as serviceWorker from './serviceWorker';

firebase.initializeApp(config);

ReactDOM.render(<AppCont store={store} />, document.getElementById('root'));

serviceWorker.unregister();
