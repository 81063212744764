import './TermSelect.css';

import { func, string } from 'prop-types';
import React from 'react';

const TermSelect = React.memo(({ term, selectTerm }) => (
  <div className="rates-term-wrap">
    <div className="rates-term-selectTerm">На какой срок?</div>
    <div className="rates-term-container">
      <button
        className={`rates-term-btn ${term === 'm1' ? 'rates-term-btn-active' : ''}`}
        onClick={() => selectTerm('m1')}
      >
        1 месяц
      </button>
      {/* <button
      className={`rates-term-btn ${
        term === 'm3' ? 'rates-term-btn-active' : ''
      }`}
      onClick={() => selectTerm('m3')}
    >
      3 месяца
    </button> */}
      <button
        className={`rates-term-btn ${term === 'm6' ? 'rates-term-btn-active' : ''}`}
        onClick={() => selectTerm('m6')}
      >
        6 месяцев
      </button>
      <button
        className={`rates-term-btn ${term === 'm12' ? 'rates-term-btn-active' : ''}`}
        onClick={() => selectTerm('m12')}
      >
        12 месяцев
      </button>
      <button
        className={`rates-term-btn ${term === 'unlim' ? 'rates-term-btn-active' : ''}`}
        onClick={() => selectTerm('unlim')}
      >
        Бессрочный
      </button>
    </div>
  </div>
));

TermSelect.propTypes = {
  selectTerm: func.isRequired,
  term: string.isRequired,
};

export default TermSelect;
