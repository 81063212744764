import './NavMenuCompact.css';

import { Dropdown, Icon } from 'antd';
import { object } from 'prop-types';
import React, { PureComponent } from 'react';

class NavMenuCompact extends PureComponent {
  static propTypes = {
    menu: object.isRequired,
  };

  state = { visible: false };

  render() {
    const { menu } = this.props;

    return (
      <Dropdown
        placement="bottomRight"
        getPopupContainer={() => document.querySelector('header')}
        overlay={<div className="dropdown-menu">{menu}</div>}
        trigger={['click']}
        onVisibleChange={e => this.setState({ visible: e })}
      >
        <Icon
          type="bars"
          className={`dropdown-menu-btn ${this.state.visible ? 'dropdown-menu-btn-active' : ''}`}
        />
      </Dropdown>
    );
  }
}

export default NavMenuCompact;
