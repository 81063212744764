import React from 'react';
import { YMInitializer } from 'react-yandex-metrika';

class YandexMetrica extends React.Component {
  render() {
    return (
      <YMInitializer
        accounts={[50729221]}
        options={{
          defer: true,
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
        }}
        version="2"
      />
    );
  }
}

export default YandexMetrica;
