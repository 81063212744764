import './Rates.css';

import { Spin } from 'antd';
import { array, bool, func, object, string } from 'prop-types';
import React from 'react';

import ModulesDescription from './ModulesDescription';
import TariffTable from './TariffTable';
import TermSelect from './TermSelect';

class Rates extends React.PureComponent {
  static propTypes = {
    fetchTariffsData: func.isRequired,
    modalToggle: func.isRequired,
    selectTerm: func.isRequired,
    tariffsData: array,
    term: string.isRequired,
    text: object,
    loading: bool,
  };

  UNSAFE_componentWillMount = () => {
    if (!this.props.tariffsData && !this.props.loading) this.props.fetchTariffsData();
  };

  render() {
    const { modalToggle, tariffsData, selectTerm, term, text } = this.props;

    return (
      <section className="rates">
        <div className="container">
          <div className="section-header">
            <h1>{text.heading}</h1>
          </div>
          <div className="rates-body">
            {text.warning1 && <span className="rates-warning">{text.warning1}</span>}
            {term && tariffsData ? (
              <>
                <TermSelect term={term} selectTerm={selectTerm} />
                <TariffTable term={term} tariffsData={tariffsData} modalToggle={modalToggle} />
              </>
            ) : (
              <div className="rates-spin-cont">
                <Spin size="large" />
              </div>
            )}
            {text.warning2 && <p className="rates-browsers-supported-warning">{text.warning2}</p>}
            <ModulesDescription modalToggle={modalToggle} />
          </div>
        </div>
      </section>
    );
  }
}

export default Rates;
