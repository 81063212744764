import firebase from 'firebase/app';

import store from '../store';

export const fetchTariffsData = () => dispatch => {
  dispatch({
    type: 'TARIFFS_DATA_REQ',
  });
  firebase
    .database()
    .ref('SETTINGS/TARIFPLAN')
    .once('value', res => {
      dispatch({
        type: 'TARIFFS_DATA_RES',
        data: res.exists()
          ? Object.values(res.val())
              .filter(tariff => tariff.available)
              .sort((a, b) => a.price.m1 - b.price.m1)
          : null,
      });
    });
};

export const fetchModulesDescription = () => dispatch => {
  dispatch({
    type: 'MODULES_DESCRIPTION_REQ',
  });
  firebase
    .database()
    .ref('SETTINGS/modules')
    .once('value', res => {
      const modules = res.val();
      /*
      Object.keys(modules.development).map(moduleName => {
        const mod = modules.development[moduleName];
        if (mod.type === 'primary') {
          modules.primary[moduleName] = { status: 'development', ...mod };
        } else if (mod.type === 'secondary') {
          modules.secondary[moduleName] = { status: 'development', ...mod };
        }
        return null;
      });
*/
      dispatch({
        type: 'MODULES_DESCRIPTION_RES',
        modulesDescription: modules,
      });
    });
};

export const fetchText = () => dispatch => {
  firebase
    .database()
    .ref('SETTINGS/landing/text')
    .once('value', res => {
      dispatch({
        type: 'TEXT_RES',
        text: res.val() || {},
      });
    });
};

export const fetchSlides = () => dispatch => {
  dispatch({
    type: 'SLIDES_REQ',
  });
  firebase
    .database()
    .ref('SETTINGS/landing/slides')
    .once('value', res => {
      const slides = res.exists() ? res.val() : null;

      // расшифруем контент в слайдах
      if (slides) {
        if (slides.about2) {
          slides.about2 = dispatch(handleSlideContent(slides.about2));
        }
        if (slides.screenshots) {
          slides.screenshots.map(
            slide => (slide.content = dispatch(handleSlideContent(slide.content)))
          );
        }
        if (slides.videos) {
          slides.videos.map(slide => (slide.content = dispatch(handleSlideContent(slide.content))));
        }
        if (slides.target) {
          slides.target.map(target => {
            if (target.videoCode) {
              target.videoCode = dispatch(handleSlideContent(target.videoCode));
            }
            if (target.case && target.case.functions) {
              target.case.functions.map(
                func => (func.video = dispatch(handleSlideContent(func.video)))
              );
            }
            return null;
          });
        }
      }

      dispatch({
        type: 'SLIDES_RES',
        slides,
      });
    });
};

export const handleSlideContent = content => () => {
  if (content.match(/iframe/)) {
    return content;
  }
  if (content.match(/<img/)) {
    return content;
  }
  if (content.match(/(.jpg|.png)/)) {
    return `<img src=${content} alt="" />`;
  }
  if (content.match(/youtu/)) {
    return `<iframe title="Video" src="https://www.youtube.com/embed/${content.slice(
      -11
    )}" frameborder="0" allowfullscreen controls="0" iv_load_policy="3" modestbranding="1" rel="0" showinfo="0"/>`;
  }
  if (content.length === 11) {
    return `<iframe title="Video" src="https://www.youtube.com/embed/${content}" frameborder="0" allowfullscreen controls="0" iv_load_policy="3" modestbranding="1" rel="0" showinfo="0"/>`;
  }
  return null;
};

export const modalToggle = (visible, activeModal) => ({
  type: 'MODAL_STATE_UPDATE',
  visible,
  activeModal,
});

export const selectTerm = term => ({ type: 'SELECT_TERM', term });

export const scrollToSection = section => dispatch => {
  section = section.target.dataset.section;
  const rect = document.querySelector(`.${section}`).getBoundingClientRect();
  window.scrollTo(0, window.scrollY + rect.top);
};

export const getURLParameter = name => dispatch => {
  const params = new URL(window.location.href).searchParams;

  return params.get(name);
};

export const saveRefCodeToSessionStorage = () => dispatch => {
  const refcode = dispatch(getURLParameter('refId'));
  const utm = dispatch(getURLParameter('utm'));

  if (refcode) {
    sessionStorage.setItem('refId', refcode);

    const silent = dispatch(getURLParameter('silent'));

    if (silent) {
      sessionStorage.setItem('silent', true);
    }
  }

  if (utm) {
    sessionStorage.setItem('utm', utm);
  }
};

export const fetchPage = pageUrl => dispatch => {
  dispatch({
    type: 'PAGE_CONTENT_REQ',
  });

  firebase
    .database()
    .ref(`pages`)
    .orderByChild('url')
    .equalTo(pageUrl)
    .limitToFirst(1)
    .once('value', res => {
      const page = Object.values(res.val() || {})[0];

      if (page) {
        if (
          page.modules &&
          !store.getState().modules.modulesDescription &&
          !store.getState().modules.loading
        ) {
          dispatch(fetchModulesDescription());
        }

        if (page.functions && !store.getState().functions.loading) {
          const loadedFunctions = store.getState().functions.functionsDescription || {};

          const functionsToLoad = page.functions
            .split(',')
            .map(func => func.trim())
            .filter(func => !loadedFunctions[func]);

          dispatch(fetchFunctionsDescription(functionsToLoad));
        }

        if (page.video) {
          page.video = dispatch(handleSlideContent(page.video));
        }
      }

      dispatch({
        type: 'PAGE_CONTENT_RES',
        page,
      });
    });
};

export const fetchFunctionsDescription = arr => async dispatch => {
  if (!arr || !arr.length) return;

  dispatch({
    type: 'FUNCTIONS_DESCRIPTION_REQ',
  });

  const functions = {};

  await Promise.all(
    arr.map(funcId =>
      firebase
        .firestore()
        .collection('wikiArticles')
        .doc(funcId)
        .get()
        .then(doc => {
          functions[funcId] = doc.data();

          if (functions[funcId].video) {
            functions[funcId].video = dispatch(handleSlideContent(functions[funcId].video));
          }
        })
    )
  );

  dispatch({
    type: 'FUNCTIONS_DESCRIPTION_RES',
    functionsDescription: functions,
  });
};

export const createClickAndDestroyLink = (url, newTab = true) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  a.rel = 'noopener noreferrer';
  a.href = url;
  if (newTab) a.target = '_blank';
  a.click();
  a.remove();
};
