const initialState = {
  functionsDescription: null,
  loading: false,
};

export default function functions(state = initialState, action) {
  switch (action.type) {
    case 'FUNCTIONS_DESCRIPTION_REQ':
      return {
        ...state,
        loading: true,
      };

    case 'FUNCTIONS_DESCRIPTION_RES':
      return {
        ...state,
        functionsDescription: {
          ...state.functionsDescription,
          ...action.functionsDescription,
        },
        loading: false,
      };

    default:
      return state;
  }
}
