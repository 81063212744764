import './Header.css';

import { func } from 'prop-types';
import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import ym from 'react-yandex-metrika';

import logo from '../../assets/images/logo.svg';
import NavMenu from './NavMenu';
import NavMenuCompact from './NavMenuCompact';

const Header = React.memo(({ scrollToSection }) => {
  const menu = (
    <>
      <ul>
        <li>
          <button className="menu-item" onClick={scrollToSection}>
            <span data-section="about2">О сервисе</span>
          </button>
        </li>
        <li>
          <button className="menu-item" onClick={scrollToSection}>
            <span data-section="target">Для кого?</span>
          </button>
        </li>
        <li>
          <button className="menu-item" onClick={scrollToSection}>
            <span data-section="advantages">Преимущества</span>
          </button>
        </li>
        <li>
          <button className="menu-item" onClick={scrollToSection}>
            <span data-section="rates">Тарифы</span>
          </button>
        </li>
        <li>
          <a
            href="/forum"
            target="_blank"
            className="menu-item"
            onClick={() => ym('reachGoal', 'forum')}
          >
            <span>Обсуждения</span>
          </a>
        </li>
        <li>
          <button className="menu-item" onClick={scrollToSection}>
            <span data-section="footer-content">Контакты</span>
          </button>
        </li>
      </ul>
      <a href="/lk" target="_blank">
        <button className="ssmm-btn ssmm-btn-green">
          <span>Личный кабинет</span>
        </button>
      </a>
    </>
  );

  return (
    <header>
      <ReactResizeDetector handleWidth>
        {({ width }) => (
          <nav>
            {width > 900 ? (
              <div className="header-cont container">
                <img src={logo} className="header-logo" title="Super-SMM" alt="logo" />
                <NavMenu menu={menu} />
              </div>
            ) : (
              <div className="header-cont container">
                <img src={logo} className="header-logo" title="Super-SMM" alt="logo" />
                <NavMenuCompact menu={menu} />
              </div>
            )}
          </nav>
        )}
      </ReactResizeDetector>
    </header>
  );
});

Header.propTypes = {
  scrollToSection: func.isRequired,
};

export default Header;
