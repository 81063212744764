import { combineReducers } from 'redux';

import finalSell from '../../components/FinalSell/reducer';
import marathonSpecial from '../../components/MarathonSpecial/reducer';
import pdfModal from '../../components/PdfModal/reducer';
import reviews from '../../components/Reviews/reducer';
import special from '../../components/Special/reducer';
import specialCombo from '../../components/SpecialCombo/reducer';
import bonusPage from './bonusPage';
import documents from './documents';
import functions from './functions';
import loaders from './loaders';
import modules from './modules';
import pages from './pages';
import partnerRedirect from './partnerRedirect';
import partnerSettings from './partnerSettings';
import paymentRedirect from './paymentRedirect';
import slider from './slider';
import tariffs from './tariffs';
import text from './text';
import webinars from './webinars';

const rootReducer = combineReducers({
  tariffs,
  modules,
  slider,
  text,
  pdfModal,
  bonusPage,
  pages,
  functions,
  webinars,
  paymentRedirect,
  partnerRedirect,
  partnerSettings,
  loaders,
  special,
  reviews,
  documents,
  specialCombo,
  finalSell,
  marathonSpecial,
});

export default rootReducer;
