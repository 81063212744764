const initialState = {
  loading: false,
  error: false,
  uid: null,
  purchase: null,
  purchaseInfo: null,
  rate: null,
  period: null,
  description: null,
  amount: null,
  pack: null,
  orderId: null,
};

export default function paymentRedirect(state = initialState, action) {
  switch (action.type) {
    case 'PAYMENT_REDIRECT_TOGGLE_LOADING':
      return { ...state, loading: action.loading };

    case 'PAYMENT_REDIRECT_SET_PARAMS':
      return {
        ...state,
        uid: action.uid,
        purchase: action.purchase,
        rate: action.rate,
        period: action.period,
        description: action.description,
        amount: action.amount,
        typeModule: action.typeModule,
        pack: action.pack,
        orderId: action.orderId,
      };

    case 'PAYMENT_REDIRECT_SET_ERROR':
      return { ...state, error: action.error };

    case 'PAYMENT_REDIRECT_SET_INFO':
      return { ...state, purchaseInfo: action.info };

    default:
      return state;
  }
}
