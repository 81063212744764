const monthNames = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];
const currentDate = new Date();
const currentMonthName = monthNames[currentDate.getMonth()];

const initialState = {
  text: {
    adv: {
      block1_heading: 'Удобный интерфейс',
      block1_text: 'Весь необходимый функционал размещен на странице во ВКонтакте',
      block2_heading: 'Экономия времени',
      block2_text: 'Снижение времени однотипной работы на 40% за счет автоматизации процессов',
      block3_heading: 'Больше возможностей',
      block3_text: 'SUPER SMM объединил возможности более 10 популярных сервисов',
      block4_heading: 'Гибкая система оплаты',
      block4_text: 'Позволяет платить только за те функции, которые необходимы в работе',
      block5_heading: 'Участие в разработке',
      block5_text: 'Настройка сервиса индивидуально под ваши потребности',
      block6_heading: 'Партнерская программа',
      block6_text:
        'Заработывайте вместе с нами либо пользуйтесь сервисом бесплатно, рекомендуя сервис',
      heading: 'Наши преимущества',
    },
    screen1: {
      heading: `Управление социальными сетями в едином сервисе\n\rза 16 рублей в день`,
      subheading1: `Единый сервис управления для бизнеса и личного бренда в привычном интерфейсе`,
      subheading2: `Попробуйте сервис бесплатно в течение 3 дней и проверьте эффективность сами`,
    },
    screen2: {
      heading: 'О сервисе',
    },
    screen3: {
      heading: 'Управляйте всеми инструментами со страницы соц. сети.',
      subheading: 'Больше не нужно открывать кучу вкладок',
      text1:
        'Сервис работает через расширение для браузера, избавляя вас от использования личного кабинета или установки каких либо программ.',
      text2: '\r\nНаучись пользоваться инновационным инструментом, чтобы обогнать всех конкурентов',
    },
    screen4: {
      heading: 'Участие в разработке',
    },
    screen5: {
      heading: 'Для кого подходит сервис?',
    },
    screen6: {
      heading: `Наши тарифы со скидкой 40% до конца ${currentMonthName}`,
      warning1: '',
      warning2: 'Внимание! На данный момент сервис работает в браузерах Chrome, Yandex и Opera.',
    },
    screen7: {
      heading: 'Активировать БЕТА доступ',
      subheading: 'ОСОБАЯ ЦЕНА НА ВРЕМЯ РАЗРАБОТКИ',
    },
    screen8: {
      heading: 'Отзывы о сервисе',
    },
  },
};

export default function modules(state = initialState, action) {
  switch (action.type) {
    case 'TEXT_RES':
      return {
        text: action.text,
      };

    default:
      return state;
  }
}
