import './TargetSlider.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spin } from 'antd';
import { object } from 'prop-types';
import React, { PureComponent } from 'react';
import Slider from 'react-slick';

import NotInTheList from './NotInTheList';
import Slide from './Slide';

class TargetSlider extends PureComponent {
  static propTypes = {
    slides: object,
  };

  state = { activeSlide: 0 };

  UNSAFE_componentWillMount = () => {
    if (!this.props.slides && !this.props.loading) this.props.fetchSlides();
  };

  render() {
    const { slides } = this.props;

    const settings = {
      arrows: false,
      dots: false,
      infinite: true,
      centerMode: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      variableWidth: false,
      beforeChange: (oldIndex, newIndex) => this.setState({ activeSlide: newIndex }),
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 790,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <div className="target-slider">
        {slides ? (
          <>
            <div className="target-slider-list">
              {slides.target.map((slide, index) => (
                <button
                  key={index}
                  className={this.state.activeSlide === index ? 'active-slide' : ''}
                  onClick={() => this.slider.slickGoTo(index)}
                >
                  <span>{slide.name}</span>
                </button>
              ))}

              <button
                key={slides.target.length}
                className={
                  this.state.activeSlide === slides.target.length
                    ? 'notinthelist active-slide'
                    : 'notinthelist'
                }
                onClick={() => this.slider.slickGoTo(slides.target.length)}
              >
                <span>Не нашли себя в списке?</span>
              </button>
            </div>
            <div className="target-slider-body">
              <div className="target-slider-left-arrow">
                <FontAwesomeIcon icon="angle-left" onClick={() => this.slider.slickPrev()} />
              </div>

              <div className="target-slider-wrap">
                <Slider ref={slider => (this.slider = slider)} {...settings}>
                  {slides.target.map((slide, index) => (
                    <Slide key={index} index={index} slide={slide} />
                  ))}
                  <NotInTheList key={slides.target.length} index={slides.target.length} />
                </Slider>
              </div>

              <div className="target-slider-right-arrow">
                <FontAwesomeIcon icon="angle-right" onClick={() => this.slider.slickNext()} />
              </div>
            </div>
          </>
        ) : (
          <div className="target-slider-spin-cont">
            <Spin size="large" />
          </div>
        )}
      </div>
    );
  }
}

export default TargetSlider;
