import './Footer.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { func } from 'prop-types';
import React from 'react';

const Footer = React.memo(({ pdfModalOpen }) => (
  <footer>
    <div className="container">
      <div className="footer-content">
        <div className="footer-soc-links">
          <span>Социальные сети:</span>
          <div className="footer-soc-links-container">
            {/* <FontAwesomeIcon
                  className="footer-soc-links-inst-icon"
                  icon={['fab', 'instagram']}
                /> */}
            <a
              target="_blank"
              href="https://vk.com/vsemsmm1"
              title="Техническая поддержка сервиса"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon className="footer-soc-links-vk-icon" icon={['fab', 'vk']} />
            </a>
          </div>
        </div>
        <div className="footer-center">
          <p>
            <span className="span-link" onClick={() => pdfModalOpen('privacyPolicy')}>
              Политика конфиденциальности
            </span>{' '}
            |{' '}
            <span className="span-link" onClick={() => pdfModalOpen('offer')}>
              Оферта
            </span>{' '}
            |{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://super-smm.com/forum"
              title="Техническая поддержка"
            >
              <span>Техническая поддержка</span>
            </a>
          </p>
          <p>Copyright © OOO “СУПЕР-СММ” 2020, All rights reserved</p>
        </div>
        <div className="footer-feedback-wrap">
          <div className="footer-feedback">
            <span>Обратная связь:</span>
            <a target="_blank" href="mailto:info@super-smm.com" rel="noopener noreferrer">
              info@super-smm.com
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
));

Footer.propTypes = {
  pdfModalOpen: func.isRequired,
};

export default Footer;
