const initialState = null;

export default function partnerSettings(state = initialState, action) {
  switch (action.type) {
    case 'PARTNER_SETTINGS_RES':
      return { ...action.partnerSettings };

    default:
      return state;
  }
}
