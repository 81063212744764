import 'firebase/database';

import firebase from 'firebase/app';
import { batch } from 'react-redux';

import store from '../store';

const fetchPartnerSettings = () => async dispatch => {
  dispatch({
    type: 'LOADERS_UPDATE',
    loaders: { main: true },
  });

  // Будам считать, что в SETTINGS/LK/domain/main хранится правильный домен для веба

  const settings = await firebase
    .database()
    .ref('SETTINGS/LK/domain/main')
    .once('value')
    .then(res => ({ base_url: res.val() }));

  batch(() => {
    dispatch({
      type: 'PARTNER_SETTINGS_RES',
      partnerSettings: settings,
    });
    dispatch({
      type: 'LOADERS_UPDATE',
      loaders: { main: false },
    });
  });

  return settings;
};

export const checkRedirect = () => async dispatch => {
  const { origin, pathname, search } = window.location;

  const redirectPages = ['/webinars', '/market', '/partner-redirect'];

  const match = redirectPages.some(redirect => pathname.indexOf(redirect) === 0);

  if (match) {
    let { partnerSettings } = store.getState();

    if (!partnerSettings) {
      partnerSettings = await dispatch(fetchPartnerSettings());
    }

    const { base_url: baseUrl } = partnerSettings;

    if (origin !== baseUrl) {
      const newUrl = `${baseUrl}${pathname}${search}`;
      window.location.href = newUrl;
    }
  }
};
