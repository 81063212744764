import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Rates from '../components/Rates/Rates';
import * as actions from '../redux/actions/actions';

const mapStateToProps = state => ({
  tariffsData: state.tariffs.data,
  term: state.tariffs.term,
  loading: state.tariffs.loading,
  text: state.text.text && state.text.text.screen6 ? state.text.text.screen6 : {},
});

const mapDispatchToProps = dispatch => ({
  fetchTariffsData: bindActionCreators(actions.fetchTariffsData, dispatch),
  selectTerm: bindActionCreators(actions.selectTerm, dispatch),
  modalToggle: bindActionCreators(actions.modalToggle, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rates);
