import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import About2 from '../components/About/About2';
import * as actions from '../redux/actions/actions';

const mapStateToProps = state => ({
  slides: state.slider.slides,
  text: state.text.text && state.text.text.screen2 ? state.text.text.screen2 : {},
});

const mapDispatchToProps = dispatch => ({
  modalToggle: bindActionCreators(actions.modalToggle, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(About2);
