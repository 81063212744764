import './App.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebook,
  faFacebookSquare,
  faInstagram,
  faOdnoklassniki,
  faOdnoklassnikiSquare,
  faVk,
} from '@fortawesome/free-brands-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faAngleLeft, faAngleRight, faCheck, faRocket } from '@fortawesome/free-solid-svg-icons';
import { func } from 'prop-types';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import ym from 'react-yandex-metrika';

import About1Cont from '../../containers/About1Cont';
import About2Cont from '../../containers/About2Cont';
import About3Cont from '../../containers/About3Cont';
import AdvantagesCont from '../../containers/AdvantagesCont';
import FooterCont from '../../containers/FooterCont';
import RatesCont from '../../containers/RatesCont';
import TargetCont from '../../containers/TargetCont';
import Education from '../Education/component';
import FinalTest from '../FinalTest/FinalTest';
import FullscreenLoader from '../FullscreenLoader';
import Header from '../Header/Header';
import history from '../history';
import LearnMore from '../LearnMore/LearnMore';
import Reviews from '../Reviews/container';
import VKServices from '../VKServices';
import YandexMetrica from '../YandexMetrica';

const ModulesDescriptionModalCont = React.lazy(() =>
  import('../../containers/ModulesDescriptionModalCont')
);
const HiddenPagesCont = React.lazy(() => import('../../containers/HiddenPagesCont'));
const Page404 = React.lazy(() => import('../Page404/Page404'));
const TargetPageCont = React.lazy(() => import('../../containers/TargetPageCont'));
const PdfModalCont = React.lazy(() => import('../PdfModal/container'));
const BonusCont = React.lazy(() => import('../../containers/BonusCont'));
const WebinarsCont = React.lazy(() => import('../../containers/WebinarsCont'));
const MarketRedirectCont = React.lazy(() => import('../../containers/MarketRedirectCont'));
const PagesCont = React.lazy(() => import('../../containers/PagesCont'));
const PaymentRedirectCont = React.lazy(() => import('../../containers/PaymentRedirectCont'));
const PartnerRedirectCont = React.lazy(() => import('../../containers/PartnerRedirectCont'));
// const Special = React.lazy(() => import('../Special/container'));
const SpecialCombo = React.lazy(() => import('../SpecialCombo/container'));
// const FinalSell = React.lazy(() => import('../FinalSell/container'));
// const MarathonSpecial = React.lazy(() => import('../MarathonSpecial/container'));
const May9 = React.lazy(() => import('../may-9/container'));
const WebinarJuly = React.lazy(() => import('../webinar-july/container'));

library.add(
  faCheck,
  faRocket,
  faAngleLeft,
  faAngleRight,
  faQuestionCircle,
  faVk,
  faInstagram,
  faFacebook,
  faOdnoklassnikiSquare,
  faOdnoklassniki,
  faFacebookSquare
);

class App extends React.PureComponent {
  componentDidMount = () => {
    const { checkRedirect, saveRefCodeToSessionStorage /* , fetchText */ } = this.props;

    checkRedirect();

    ym('hit', history.location.pathname + history.location.search);

    history.listen(location => {
      checkRedirect();

      ym('hit', location.pathname + location.search);
    });

    saveRefCodeToSessionStorage();
    // fetchText();}
  };

  render() {
    const { store, scrollToSection, loading } = this.props;

    return (
      <div className="App">
        <Suspense fallback={<FullscreenLoader />}>
          <YandexMetrica />
          <VKServices />

          <Provider store={store}>
            <Router history={history}>
              {loading ? (
                <FullscreenLoader />
              ) : (
                <Switch>
                  {/* <Route exact path="/special-combo" component={SpecialCombo} /> */}
                  <Route exact path="/pages/:pageUrl?" component={PagesCont} />
                  <Route exact path="/hidden-pages/:pageUrl" component={HiddenPagesCont} />
                  <Route exact path="/target/:target" component={TargetPageCont} />
                  <Route exact path="/bonus" component={BonusCont} />
                  <Route exact path="/webinars" component={WebinarsCont} />
                  <Route exact path="/market" component={MarketRedirectCont} />
                  <Route exact path="/payment-yandex" component={PaymentRedirectCont} />
                  <Route exact path="/special-june2023" component={May9} />
                  <Route exact path="/webinar" component={WebinarJuly} />
                  {/* <Route exact path="/final-sell" component={FinalSell} /> */}
                  {/* <Route exact path="/special-marathon" component={MarathonSpecial} /> */}
                  <Route
                    exact
                    path="/payment-redirect"
                    render={() => {
                      window.location.href = 'https://super-smm.com/lk/shop';
                    }}
                  />
                  <Route exact path="/partner-redirect" component={PartnerRedirectCont} />
                  <Route
                    exact
                    path="/"
                    component={() => (
                      <>
                        <Header scrollToSection={scrollToSection} />
                        <About1Cont />
                        <LearnMore scrollToSection={scrollToSection} />
                        <About2Cont />
                        <About3Cont />
                        <Education />
                        <TargetCont />
                        <AdvantagesCont />
                        <FinalTest />
                        <RatesCont />
                        <Reviews />
                        <FooterCont />

                        <ModulesDescriptionModalCont />
                      </>
                    )}
                  />
                  <Route
                    path={['/lk', '/forum', '/auth']}
                    render={() => {
                      const url = window.location.href;
                      window.location.href = url;
                      return null;
                    }}
                  />
                  <Route component={() => <Page404 />} />
                </Switch>
              )}
            </Router>

            <PdfModalCont />
          </Provider>
        </Suspense>
      </div>
    );
  }
}

App.propTypes = {
  scrollToSection: func.isRequired,
};

export default App;
