import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import App from '../components/App/App';
import * as actions from '../redux/actions/actions';
import * as partnerActions from '../redux/actions/partner';

const mapStateToProps = state => ({
  loading: state.loaders.main,
});

const mapDispatchToProps = dispatch => ({
  scrollToSection: bindActionCreators(actions.scrollToSection, dispatch),
  saveRefCodeToSessionStorage: bindActionCreators(actions.saveRefCodeToSessionStorage, dispatch),
  fetchText: bindActionCreators(actions.fetchText, dispatch),
  checkRedirect: bindActionCreators(partnerActions.checkRedirect, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
