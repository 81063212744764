import { connect } from 'react-redux';

import About3 from '../components/About/About3';

const mapStateToProps = state => ({
  slides: state.slider.slides,
  text: state.text.text && state.text.text.screen3 ? state.text.text.screen3 : {},
});

export default connect(mapStateToProps)(About3);
