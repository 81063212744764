import './TariffTable.css';

import { Icon, Popover } from 'antd';
import { array, func, string } from 'prop-types';
import React from 'react';
import ReactResizeDetector from 'react-resize-detector';

import TariffTableCols from './TariffTableCols';

const TariffTable = React.memo(({ tariffsData, term, modalToggle }) => (
  <div
    className={`rates-table${term === 'm6' || term === 'm12' ? ' rates-table-extra-height' : ''}`}
  >
    <div className="rates-table-header">
      <div className="rates-table-header-header">
        <h4>Тарифы</h4>
      </div>
      <div className="rates-table-header-body">
        <div className="rates-table-header-cell-wrapper">
          <div className="rates-table-header-cell-content">
            <span>
              <span onClick={() => modalToggle(true, 'primary')} className="modules-desc-link">
                Основные модули
              </span>
              <Popover
                overlayClassName="rates-table-popover"
                content={
                  <div style={{ maxWidth: 400 }}>
                    Основной функционал. Каждый модуль содержит в себе комплекс функций,
                    объединённых одним родом задач. По своей функциональности сопоставим с отдельно
                    работающим сервисом.
                  </div>
                }
              >
                <Icon type="question-circle" />
              </Popover>
            </span>
          </div>
        </div>
        {/* <div className="rates-table-header-cell-wrapper">
          <div className="rates-table-header-cell-content">
            <span>
              <span onClick={() => modalToggle(true, 'secondary')} className="modules-desc-link">
                Вспомогательные модули
              </span>
              <Popover
                overlayClassName="rates-table-popover"
                content={
                  <div style={{ maxWidth: 400 }}>
                    Простые функции, решающие быстро небольшие задачи, значительно экономя Вам
                    время. Вспомогательный модуль может быть частью основного модуля или отдельной
                    функцией. При активации основного модуля, все вспомогательные модули, которые в
                    него входят, будут недоступны для выбора, так как включены в основной модуль.
                  </div>
                }
              >
                <Icon type="question-circle" />
              </Popover>
            </span>
          </div>
              </div> */}
        {/* <div className="rates-table-header-cell-wrapper">
          <div className="rates-table-header-cell-content">
            <span>
              Количество аккаунтов
              <Popover
                overlayClassName="rates-table-popover"
                content={
                  <div style={{ maxWidth: 400 }}>
                    Это количество дополнительных аккаунтов, которые вы можете добавить в модуль
                    "Аккаунты"для централизованного управления. Добавлять аккаунты можно только тех
                    соц. сетей, которые разрешены тарифом.
                  </div>
                }
              >
                <Icon type="question-circle" />
              </Popover>
            </span>
          </div>
        </div>
        <div className="rates-table-header-cell-wrapper">
          <div className="rates-table-header-cell-content">
            <span>
              Замена модуля
              <Popover
                overlayClassName="rates-table-popover"
                content={
                  <div style={{ maxWidth: 400 }}>
                    Эта опция позволяет бесплатно заменить один модуль на другой. Доступна только в
                    годовых пакетах. Во всех остальных случаях, заменить один модуль на другой можно
                    платно.
                  </div>
                }
              >
                <Icon type="question-circle" />
              </Popover>
            </span>
          </div>
        </div>

        <div className="rates-table-header-cell-wrapper">
          <div className="rates-table-header-cell-content">
            <span>
              Количество соц. сетей
              <Popover
                overlayClassName="rates-table-popover"
                content={
                  <div style={{ maxWidth: 400 }}>
                    Показывает в каких соц. сетях будет работать сервис при выборе тарифа. На данный
                    момент большинство функций работает только для Вконтакте. Под другие соц. сети
                    ведётся разработка.
                  </div>
                }
              >
                <Icon type="question-circle" className="rates-table-header-soc-info" />
              </Popover>
            </span>
          </div>
        </div>

        {/* <div className="rates-table-header-cell-wrapper">
          <div className="rates-table-header-cell-content">
            <span>
              Особенные привилегии
                            <Popover content=''>               <Icon type="question-circle" />   </Popover>
            </span>
          </div>
</div> */}

        <div className="rates-table-header-cell-wrapper">
          <div className="rates-table-header-cell-content rates-table-header-cell-price">
            <span>ИТОГО</span>
          </div>
        </div>
      </div>
    </div>
    <ReactResizeDetector handleWidth>
      {({ width }) => (
        <div className="rates-table-cols-wrap">
          <TariffTableCols
            tariffsData={tariffsData}
            term={term}
            modalToggle={modalToggle}
            width={width}
          />
        </div>
      )}
    </ReactResizeDetector>
  </div>
));

TariffTable.propTypes = {
  tariffsData: array.isRequired,
  term: string.isRequired,
  modalToggle: func.isRequired,
};

export default TariffTable;
