import './ModalSlider.css';

import { Modal } from 'antd';
import { object } from 'prop-types';
import React from 'react';
import Slider from 'react-slick';

const ModalSlider = React.memo(({ type, slides, activeSlide, handleCloseModal }) => {
  const Next = props => <div onClick={props.onClick} className="slick-next-custom" />;

  const Prev = props => <div onClick={props.onClick} className="slick-prev-custom" />;

  const settings = {
    arrows: true,
    dots: false,
    prevArrow: <Prev />,
    nextArrow: <Next />,
    lazyLoad: 'ondemand',
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: activeSlide,
    variableWidth: false,
    // Я бы с радостью делал video.pause(), но
    // el.querySelector('iframe').contentWindow.document.querySelector('video')
    // выдавал ошибку про cors
    ...(type === 'video'
      ? {
          beforeChange: () => {
            const currentSlide = document.querySelector('.modal-slider .slick-active');

            const videoPlayer = currentSlide.innerHTML;
            currentSlide.innerHTML = videoPlayer;
          },
        }
      : {}),
  };

  return (
    <Modal
      className={`modal-slider modal-slider-${type}`}
      visible
      title={type === 'images' ? 'Скриншоты сервиса' : 'Видео о сервисе'}
      footer={null}
      destroyOnClose
      onCancel={handleCloseModal}
    >
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="modal-slider-slide">
            {slide.content ? (
              <div
                className="modal-slider-video-slide"
                dangerouslySetInnerHTML={{ __html: slide.content }}
              />
            ) : (
              <div style={{ width: '100%', textAlign: 'center' }}>Слайд недоступен</div>
            )}
          </div>
        ))}
      </Slider>
    </Modal>
  );
});

ModalSlider.propTypes = {
  slides: object,
};

export default ModalSlider;
