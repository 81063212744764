import '../style/index.css';

import { Button } from 'antd';
import React from 'react';

import bot from '../images/bot.png';
import tg from '../images/tg.png';
import web from '../images/web.png';

const Education = React.memo(() => (
  <section className="education-section">
    <div className="container">
      <div className="section-header">
        <h1>Сложно? Это поможет разобраться во всём!</h1>
      </div>

      <div className="education-content">
        <div className="education-col">
          <h3 className="education-col-title">Обучающие вебинары</h3>

          <img className="education-col-image" src={web} title="Вебинары" alt="Вебинары" />

          <div className="education-col-desc">
            Каждую среду в 19:00 мы проводим живой обучающий вебинар
          </div>

          <a
            href="https://vk.com/app5898182_-150194726#s=373658"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button size="large" type="primary">
              Записаться
            </Button>
          </a>
        </div>
        <div className="education-col">
          <h3 className="education-col-title">ВК-БОТ училка</h3>

          <img className="education-col-image" src={bot} title="ВК-Бот" alt="ВК-Бот" />

          <div className="education-col-desc">
            Научиться пользоваться самостоятельно по урокам от чат-бота
          </div>

          <a href="https://vk.me/chatbotsupersmm" target="_blank" rel="noopener noreferrer">
            <Button size="large" type="primary">
              Начать
            </Button>
          </a>
        </div>
        <div className="education-col">
          <h3 className="education-col-title">Телеграм канал</h3>

          <img className="education-col-image" src={tg} title="Телеграм" alt="Телеграм" />

          <div className="education-col-desc">
            Будь в курсе последних новостей на нашем телеграм канале
          </div>

          <a href="https://t.me/SuperSmmOfficial" target="_blank" rel="noopener noreferrer">
            <Button size="large" type="primary">
              Зайти
            </Button>
          </a>

          <a
            className="education-tg-link"
            href="tg://resolve?domain=SuperSmmOfficial"
            target="_blank"
            rel="noopener noreferrer"
          >
            @SuperSmmOfficial
          </a>
        </div>
      </div>
    </div>
  </section>
));

export default Education;
