const initialState = {
  privacyPolicy: null,
  partnerProgramAgreement: null,
};

export default function documents(state = initialState, action) {
  switch (action.type) {
    case 'DOCUMENT_URL_RES':
      return {
        ...state,
        [action.documentName]: action.url,
      };

    default:
      return state;
  }
}
