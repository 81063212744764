import './Advantages.css';

import { object } from 'prop-types';
import React from 'react';
import ym from 'react-yandex-metrika';

import adv from '../../assets/images/advantages/advantages.jpg';

const Advantages = React.memo(({ text }) => (
  <section className="advantages">
    <div className="container">
      <div className="section-header">
        <h1>{text.heading}</h1>
      </div>

      <div className="advantages-body">
        <div className="advantages-left">
          <div className="advantages-block">
            <h3>{text.block1_heading}</h3>
            <span>{text.block1_text}</span>
          </div>
          <div className="advantages-block">
            <h3>{text.block2_heading}</h3>
            <span>{text.block2_text}</span>
          </div>
          <div className="advantages-block">
            <h3>{text.block3_heading}</h3>
            <span>{text.block3_text}</span>
          </div>
        </div>
        <div className="advantages-center">
          <a
            className="ssmm-btn ssmm-btn-green ssmm-btn-large"
            target="_blank"
            title="Стать партнером"
            href="/lk"
            rel="noopener noreferrer"
            onClick={() => ym('reachGoal', '6_pushpartner')}
          >
            <span>Стать партнером</span>
          </a>
          <div>
            <img src={adv} alt="" />
          </div>
        </div>
        <div className="advantages-right">
          <div className="advantages-block">
            <h3>{text.block4_heading}</h3>
            <span>{text.block4_text}</span>
          </div>
          <div className="advantages-block">
            <h3>{text.block5_heading}</h3>
            <span>{text.block5_text}</span>
          </div>
          <div className="advantages-block">
            <h3>{text.block6_heading}</h3>
            <span>{text.block6_text}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
));

Advantages.propTypes = {
  text: object,
};

export default Advantages;
