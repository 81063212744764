import './AboutSlider.css';

import { array, string } from 'prop-types';
import React, { PureComponent } from 'react';
import Slider from 'react-slick';

import ModalSlider from './ModalSlider';

class AboutSlider extends PureComponent {
  static propTypes = {
    slides: array,
    type: string,
  };

  state = { modalVisible: false, activeSlide: 0 };

  handleSlideClick = slide => {
    if (!this.props.slides[slide.target.dataset.slide].content) return;
    this.setState({
      modalVisible: true,
      activeSlide: slide.target.dataset.slide,
    });
  };

  handleCloseModal = () => this.setState({ modalVisible: false });

  render() {
    const { slides, type } = this.props;

    const Next = props => <div onClick={props.onClick} className="slick-next-custom" />;

    const Prev = props => <div onClick={props.onClick} className="slick-prev-custom" />;

    const settings = {
      arrows: true,
      dots: false,
      prevArrow: <Prev />,
      nextArrow: <Next />,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 1150,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <div className={`about3-slider about3-slider-${type}`}>
        <Slider {...settings}>
          {slides
            .sort((a, b) => (a.order || 0) - (b.order || 0))
            .map((slide, index) => (
              <div key={index}>
                <div
                  data-slide={index}
                  onClick={this.handleSlideClick}
                  className="about3-slider-slide"
                  style={{ backgroundImage: `url(${slide.preview})` }}
                />
                {slide.title && <div className="about3-slider-slide-title">{slide.title}</div>}
              </div>
            ))}
        </Slider>

        {this.state.modalVisible && (
          <ModalSlider
            type={type}
            slides={slides}
            activeSlide={this.state.activeSlide}
            handleCloseModal={this.handleCloseModal}
          />
        )}
      </div>
    );
  }
}

export default AboutSlider;
