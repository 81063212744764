import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Target from '../components/Target/Target';
import * as actions from '../redux/actions/actions';

const mapStateToProps = state => ({
  slides: state.slider.slides,
  loading: state.slider.loading,
  text: state.text.text && state.text.text.screen5 ? state.text.text.screen5 : {},
});

const mapDispatchToProps = dispatch => ({
  fetchSlides: bindActionCreators(actions.fetchSlides, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Target);
