import './About3.css';

import { Spin } from 'antd';
import { object } from 'prop-types';
import React from 'react';

import AboutSlider from './AboutSlider';

const About3 = React.memo(({ slides, text }) => (
  <section className="about3">
    <div className="container">
      <div className="section-header">
        <h1>{text.heading}</h1>
        <p className="subheader">{text.subheading}</p>
      </div>
      <div className="about3-body">
        <div className="about3-left">
          <p>{text.text1}</p>
          <p>{text.text2}</p>
        </div>
        <div className="about3-right">
          {slides ? (
            <>
              <AboutSlider slides={slides.screenshots} type="images" />
              <AboutSlider slides={slides.videos} type="video" />
            </>
          ) : (
            <Spin size="large" />
          )}
        </div>
      </div>
    </div>
  </section>
));

About3.propTypes = {
  slides: object,
  text: object,
};

export default About3;
