const initialState = {
  redirectParams: null,
  partnerId: null,
  loading: false,
};

export default function partnerRedirect(state = initialState, action) {
  switch (action.type) {
    case 'PARTNER_REDIRECT_RESET_STATE':
      return initialState;

    case 'PARTNER_REDIRECT_PARAMS_REQ':
      return { redirectParams: null, partnerId: null, loading: true };

    case 'PARTNER_REDIRECT_PARAMS_RES':
      return { redirectParams: action.redirectParams, partnerId: action.partnerId, loading: false };

    default:
      return state;
  }
}
