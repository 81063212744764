const initialState = {
  slides: null,
  loading: false,
};

export default function slider(state = initialState, action) {
  switch (action.type) {
    case 'SLIDES_REQ':
      return {
        ...state,
        loading: true,
      };

    case 'SLIDES_RES':
      return {
        ...state,
        slides: action.slides,
        loading: false,
      };
    default:
      return state;
  }
}
