const initialState = {
  term: 'm1',
  data: null,
  loading: false,
};

export default function tariffs(state = initialState, action) {
  switch (action.type) {
    case 'SELECT_TERM':
      return { ...state, term: action.term };

    case 'TARIFFS_DATA_REQ':
      return { ...state, loading: true };
    case 'TARIFFS_DATA_RES':
      return { ...state, data: action.data, loading: false };

    default:
      return state;
  }
}
