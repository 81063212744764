const initialState = {
  reviews: null,
};

export default function reviews(state = initialState, action) {
  switch (action.type) {
    case 'REVIEWS_RES':
      return { ...state, reviews: action.reviews };

    default:
      return state;
  }
}
