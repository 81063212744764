const initialState = {
  termsAccepted: false,
};

export default function special(state = initialState, action) {
  switch (action.type) {
    case 'SPECIAL_TOGGLE_TERMS_ACCEPTED':
      return { termsAccepted: !state.termsAccepted };

    default:
      return state;
  }
}
