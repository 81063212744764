import './SpecialOfferDescriptionModal.css';

import { Modal } from 'antd';
import { func } from 'prop-types';
import React from 'react';

const SpecialOfferDescriptionModal = React.memo(({ toggleSpecialOfferDescriptionModal }) => {
  return (
    <Modal
      className="special-offer-desc"
      title='Покупка тарифа "Раритетный" по специальной цене'
      visible
      footer={null}
      destroyOnClose
      onCancel={toggleSpecialOfferDescriptionModal}
    >
      <p>
        Специальное предложение действует только для новых пользователей. Купить тариф "Раритетный"
        по цене тарифа "Эксперт" со скидкой 60%. Данное предложение будет действовать только при
        первой покупке. Вы сможете купить тариф на 1, 6 или 12 месяцев сразу.
      </p>

      <p>
        Для покупки просто зарегистрируйтесь в системе и предложение будет доступно в течение 3х
        дней в личном кабинете или через расширение в разделе "Покупка и активация".
      </p>

      <div className="special-offer-desc-buttonCont">
        <a href="/lk/shop" className="ssmm-btn ssmm-btn-green">
          <span>Купить спец. предложение</span>
        </a>
      </div>
    </Modal>
  );
});

SpecialOfferDescriptionModal.propTypes = {
  toggleSpecialOfferDescriptionModal: func,
};

export default SpecialOfferDescriptionModal;
