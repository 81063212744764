import './About2.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spin } from 'antd';
import { func, object } from 'prop-types';
import React from 'react';
import ym from 'react-yandex-metrika';

import video from '../../assets/images/about/video.png';

const About2 = React.memo(({ slides, text, modalToggle }) => (
  <section className="about2">
    <div className="container">
      <div className="section-header">
        <h1>{text.heading}</h1>
      </div>
      <div className="about2-body">
        <div className="about2-left">
          <Spin spinning={!slides}>
            {slides && (
              <div
                className="ssmm-video-wrap"
                dangerouslySetInnerHTML={{
                  __html: slides.about2 || `<img src=${video} alt="" />`,
                }}
              />
            )}
          </Spin>
        </div>
        <div className="about2-right">
          <h2>
            Избавьтесь от однотипных действий и повысьте эффективность работы на 40%
            с&nbsp;инструментами на ваш выбор
          </h2>
          <div className="about2-list">
            <span className="list-item">
              <FontAwesomeIcon icon="check" />
              Автопостинг
            </span>
            <span className="list-item">
              <FontAwesomeIcon icon="check" />
              Автопубликация историй
            </span>
            <span className="list-item">
              <FontAwesomeIcon icon="check" />
              Парсинг
            </span>
            <span className="list-item">
              <FontAwesomeIcon icon="check" />
              Модуль CRM
            </span>
            <span className="list-item">
              <FontAwesomeIcon icon="check" />
              Автоматизация
            </span>
            <span className="list-item">
              <FontAwesomeIcon icon="check" />
              Управление аккаунтами
            </span>
            <span className="list-item">
              <FontAwesomeIcon icon="check" />
              Управление командами
            </span>
            <span className="list-item">
              <FontAwesomeIcon icon="check" />
              Повышение репутации
            </span>
            <span className="list-item">
              <FontAwesomeIcon icon="check" />
              Геймификация
            </span>
            <span
              className="list-item-more list-item"
              onClick={() => {
                ym('reachGoal', '2_instrument');
                modalToggle(true, 'all');
              }}
            >
              <FontAwesomeIcon icon="rocket" />
              Список всех инструментов
            </span>
          </div>
          <a
            href="/lk/shop"
            target="_blank"
            className="ssmm-btn ssmm-btn-green ssmm-btn-large"
            onClick={() => ym('reachGoal', '2_pushinstall')}
          >
            <span>Создайте свой инструмент</span>
          </a>
        </div>
      </div>
    </div>
  </section>
));

About2.propTypes = {
  text: object,
  modalToggle: func.isRequired,
};

export default About2;
