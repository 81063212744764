import './NavMenu.css';

import { object } from 'prop-types';
import React from 'react';

const NavMenu = React.memo(({ menu }) => <div className="regular-menu">{menu}</div>);

NavMenu.propTypes = {
  menu: object.isRequired,
};

export default NavMenu;
