import './TariffTableCols.css';

import { array, func, string } from 'prop-types';
import React, { PureComponent } from 'react';

import SpecialOfferDescriptionModal from './SpecialOfferDescriptionModal';
import TariffTableCol from './TariffTableCol';

class TariffTableCols extends PureComponent {
  static propTypes = {
    tariffsData: array.isRequired,
    term: string.isRequired,
    modalToggle: func.isRequired,
  };

  state = {
    cols: 4,
    colsVisible: [0, 1, 2, 3],
    openSpecialOfferDescriptionModal: false,
  };

  toggleSpecialOfferDescriptionModal = () => {
    const { openSpecialOfferDescriptionModal } = this.state;

    this.setState({
      openSpecialOfferDescriptionModal: !openSpecialOfferDescriptionModal,
    });
  };

  componentDidUpdate = prevProps => {
    if (this.props.width !== prevProps.width) {
      let cols = Math.floor(this.props.width / 160); // Сколько колонок влезет
      let colsVisible = this.state.colsVisible.slice();
      const indexMax = this.props.tariffsData.length; // Сколько колонок всего
      if (cols > indexMax) cols = indexMax; // Если может влезть больше...

      if (cols && cols !== this.state.cols) {
        // Если надо укоротить массив
        if (cols < colsVisible.length) {
          colsVisible = colsVisible.slice(0, cols);
        } // Если надо удлинить массив
        else if (cols > colsVisible.length) {
          for (let i = cols - colsVisible.length; i > 0; i--) {
            // Если 1й элемент - старт, надо добавить
            // элемент на 1 больше максимального в конец
            if (colsVisible[0] === 0) {
              colsVisible.push(Math.max(...colsVisible) + 1);
            } // И наоборот
            else {
              colsVisible = [Math.min(...colsVisible) - 1].concat(colsVisible);
            }
          }
        }
        this.setState({ cols, colsVisible });
      }
    }
  };

  handleClickNext = () => {
    let colsVisible = this.state.colsVisible.slice();
    colsVisible = colsVisible.map(index => index + 1);
    this.setState({ colsVisible });
  };

  handleClickPrev = () => {
    let colsVisible = this.state.colsVisible.slice();
    colsVisible = colsVisible.map(index => index - 1);
    this.setState({ colsVisible });
  };

  render() {
    const { tariffsData, term, modalToggle } = this.props;
    const { cols, colsVisible, openSpecialOfferDescriptionModal } = this.state;

    return (
      <>
        {openSpecialOfferDescriptionModal && (
          <SpecialOfferDescriptionModal
            toggleSpecialOfferDescriptionModal={this.toggleSpecialOfferDescriptionModal}
          />
        )}

        {tariffsData.map((tariffData, index) => {
          if (colsVisible.includes(index)) {
            return (
              <React.Fragment key={tariffData.alias}>
                {index === colsVisible[0] && cols < tariffsData.length && (
                  <div
                    className={`rates-table-cols-prevBtn ${
                      colsVisible[0] !== 0 ? 'rates-table-cols-prevBtn-active' : ''
                    }`}
                    onClick={colsVisible[0] !== 0 ? this.handleClickPrev : null}
                  >
                    <div />
                  </div>
                )}
                <TariffTableCol
                  index={index}
                  tariffData={tariffData}
                  term={term}
                  modalToggle={modalToggle}
                  toggleSpecialOfferDescriptionModal={this.toggleSpecialOfferDescriptionModal}
                />
                {index === colsVisible[colsVisible.length - 1] && cols < tariffsData.length && (
                  <div
                    className={`rates-table-cols-nextBtn ${
                      index !== tariffsData.length - 1 ? 'rates-table-cols-nextBtn-active' : ''
                    }`}
                    onClick={index !== tariffsData.length - 1 ? this.handleClickNext : null}
                  >
                    <div />
                  </div>
                )}
              </React.Fragment>
            );
          }
          return null;
        })}
      </>
    );
  }
}

export default TariffTableCols;
