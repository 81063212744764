import { func } from 'prop-types';
import React from 'react';

const ModulesDescription = React.memo(({ modalToggle }) => (
  <div className="modules-desc">
    <div className="modules-desc-links">
      <span>
        Список всех{' '}
        <span className="modules-desc-link" onClick={() => modalToggle(true, 'primary')}>
          основных модулей
        </span>{' '}
        и их описание
      </span>
      {/* <br />
      <span>
        Список всех{' '}
        <span
          className="modules-desc-link"
          onClick={() => modalToggle(true, 'secondary')}
        >
          вспомогательных модулей
        </span>{' '}
        и их описание
      </span> */}
    </div>
  </div>
));

ModulesDescription.propTypes = {
  modalToggle: func.isRequired,
};

export default ModulesDescription;
