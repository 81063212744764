import './About1.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { object } from 'prop-types';
import React from 'react';
import ym from 'react-yandex-metrika';

const About1 = React.memo(({ text }) => (
  <section className="about1">
    <div className="container">
      <div className="about1-left">
        <h1>{text.heading}</h1>
        <div className="about1-subheader">
          <p>{text.subheading1}</p>
          {/* <p>
            <strong>{text.subheading2}</strong>
          </p> */}
        </div>
        <div className="about1-buttons-wrap">
          <a
            href="/lk/shop"
            target="_blank"
            title="Попробовать бесплатно"
            className="ssmm-btn ssmm-btn-large about1-btn"
            onClick={() => ym('reachGoal', '1_pushfree')}
          >
            <span>Попробовать бесплатно</span>
          </a>
          <div className="about1-soc-buttons-cont">
            <div className="about1-soc-button">
              <FontAwesomeIcon icon={['fab', 'vk']} />
            </div>
            <div className="about1-soc-button">
              <FontAwesomeIcon icon={['fab', 'instagram']} />
            </div>
          </div>
        </div>
      </div>
      <div className="about1-right" />
    </div>
  </section>
));

About1.propTypes = {
  text: object,
};

export default About1;
