import './Target.css';

import { object } from 'prop-types';
import React from 'react';

import TargetSlider from './TargetSlider';

const Target = React.memo(({ slides, fetchSlides, text }) => (
  <section className="target">
    <div className="container">
      <div className="section-header">
        <h1>{text.heading}</h1>
      </div>

      <TargetSlider slides={slides} fetchSlides={fetchSlides} />
    </div>
  </section>
));

Target.propTypes = {
  slides: object,
  text: object,
};

export default Target;
