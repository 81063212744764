const initialState = {
  page: null,
  loading: false,
};

export default function pages(state = initialState, action) {
  switch (action.type) {
    case 'PAGE_CONTENT_REQ':
      return {
        ...state,
        page: null,
        loading: true,
      };

    case 'PAGE_CONTENT_RES':
      return {
        ...state,
        page: action.page,
        loading: false,
      };

    default:
      return state;
  }
}
