import './LearnMore.css';

import { func } from 'prop-types';
import React from 'react';

const LearnMore = React.memo(({ scrollToSection }) => (
  <section className="learn-more-cont">
    <div className="container">
      <span data-section="about2" className="learn-more" onClick={scrollToSection}>
        узнай больше
      </span>
    </div>
  </section>
));

LearnMore.propTypes = {
  scrollToSection: func.isRequired,
};

export default LearnMore;
