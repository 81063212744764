import React from 'react';
import VK, { CommunityMessages } from 'react-vk';

const forbiddenUrls = ['/bonus', '/payment-redirect'];

const ifUrlForbidden = () => {
  const location = window.location.href;

  return Boolean(forbiddenUrls.find(url => location.includes(url)));
};

const VKServices = () => (
  <VK
    onApiAvailable={() => {
      window.VK.Retargeting.Init('VK-RTRG-425648-Kb3s');
      window.VK.Retargeting.Hit();
    }}
  >
    {!ifUrlForbidden() && <CommunityMessages groupId={183713313} />}
  </VK>
);

export default VKServices;
