import './NotInTheList.css';

import { Button } from 'antd';
import React from 'react';
import ym from 'react-yandex-metrika';

const NotInTheList = React.memo(() => {
  return (
    <div className="target-slide-wrap">
      <div className="target-slide shadow">
        <div className="target-slide-header">
          <h3>Не нашли себя в списке?</h3>
        </div>
        <div className="target-slide-list">
          <span className="list-item">
            Мы постоянно добавляем новый функционал под разные ЦА. Расскажите нам, какие функции Вам
            нужны и мы добавим их в сервис.
          </span>
        </div>
        <div className="target-slide-case">
          <a
            target="_blank"
            href="/forum"
            title="Форум Super-SMM"
            rel="noopener noreferrer"
            onClick={() => ym('reachGoal', '5_NONE')}
          >
            <Button type="primary" className="target-slide-notInTheList-button">
              Предложить функцию
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
});

export default NotInTheList;
