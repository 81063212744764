import { connect } from 'react-redux';

import * as actions from '../actions';
import Reviews from '../component';

const mapStateToProps = state => ({
  reviews: state.reviews.reviews,
  text: state.text.text && state.text.text.screen8 ? state.text.text.screen8 : {},
});

export default connect(mapStateToProps, { ...actions })(Reviews);
