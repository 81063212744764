const initialState = {
  pdfModalOpen: false,
  pdfModalTitle: null,
  pdfModalUrl: null,
};

export default function pdfModal(state = initialState, action) {
  switch (action.type) {
    case 'PDF_MODAL_OPEN':
      return {
        ...state,
        pdfModalOpen: true,
        pdfModalTitle: action.title,
        pdfModalUrl: action.url,
      };

    case 'PDF_MODAL_CLOSE':
      return {
        ...state,
        pdfModalOpen: false,
        pdfModalTitle: null,
        pdfModalUrl: null,
      };

    default:
      return state;
  }
}
