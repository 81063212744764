const initialState = {
  activeModal: '',
  modalVisible: false,
  modulesDescription: null,
  loading: false,
};

export default function modules(state = initialState, action) {
  switch (action.type) {
    case 'MODAL_STATE_UPDATE':
      return {
        ...state,
        modalVisible: action.visible,
        ...(action.activeModal ? { activeModal: action.activeModal } : {}),
      };

    case 'MODULES_DESCRIPTION_REQ':
      return {
        ...state,
        loading: true,
      };

    case 'MODULES_DESCRIPTION_RES':
      return {
        ...state,
        modulesDescription: action.modulesDescription,
        loading: false,
      };

    default:
      return state;
  }
}
