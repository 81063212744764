import './Slide.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import { number, object } from 'prop-types';
import React from 'react';
import ym from 'react-yandex-metrika';

const Slide = React.memo(({ slide, index }) => {
  return (
    <div className="target-slide-wrap">
      <div className="target-slide shadow">
        <div className="target-slide-header">
          <h3>{slide.name}</h3>
        </div>
        <div className="target-slide-list">
          {slide.functions.map((func, index) => (
            <span key={index} className="list-item">
              <FontAwesomeIcon icon="check" />
              {func}
            </span>
          ))}

          <span className="list-item list-item-more">
            <FontAwesomeIcon icon="rocket" />И другие функции...
          </span>
        </div>
        <div className="target-slide-case">
          {slide.caseLink && (
            <a
              href={`/target/${index + 1}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => (slide.metrikaGoal ? ym('reachGoal', slide.metrikaGoal) : null)}
            >
              <Button type="primary">Подробнее</Button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
});

Slide.propTypes = {
  slide: object.isRequired,
  index: number.isRequired,
};

export default Slide;
