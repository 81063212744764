import '../style/index.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { array, func, object } from 'prop-types';
import React, { useEffect } from 'react';
import Slider from 'react-slick';

import star from '../images/ico-star.png';

let slider = {};

const Reviews = React.memo(({ loadReviews, reviews, text }) => {
  useEffect(() => {
    if (!reviews) {
      loadReviews();
    }
    // eslint-disable-next-line
  }, []);

  if (!reviews || !reviews.length || !text) return null;

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    centerMode: !(reviews.length > 1),
    slidesToShow: reviews.length > 1 ? 2 : 1,
    slidesToScroll: reviews.length > 1 ? 2 : 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="reviews-section">
      <div className="container">
        <div className="section-header">
          <h1>{text.heading}</h1>
        </div>

        <div className="reviews-slider">
          <Slider
            ref={sld => {
              slider = sld;
            }}
            {...settings}
          >
            {reviews.map((review, index) => (
              <div className="review-box" key={index}>
                <div
                  className="photo"
                  style={{
                    background: `url('${review.user.photo}') no-repeat center center`,
                    backgroundSize: 'cover',
                  }}
                />

                <div className="head">
                  <div className="review-name">
                    <span className="name">{review.user.name}</span>
                    <div className="rating">
                      <img src={star} alt="Star" />
                      <img src={star} alt="Star" />
                      <img src={star} alt="Star" />
                      <img src={star} alt="Star" />
                      <img src={star} alt="Star" />
                    </div>
                  </div>
                  <div className="review-info">
                    {!!review.activity && (
                      <span className="review-activity">{review.activity}</span>
                    )}
                    <span className="review-title">{review.title}</span>
                  </div>
                </div>

                <div className="text-review">
                  <p>{review.message}</p>
                </div>
                <a
                  href={`https://vk.com/id${review.user.vk}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="ssmm-btn ssmm-btn-white ssmm-btn-large">
                    <span>Ссылка на страничку</span>
                  </button>
                </a>
              </div>
            ))}
          </Slider>

          <div className="arrow-box">
            <div className="pagingInfo">
              <div className="reviews-slider-left-arrow">
                <FontAwesomeIcon icon="angle-left" onClick={() => slider.slickPrev()} />
              </div>
              Листать отзывы {reviews && <span>{reviews.length}</span>}
              <div className="reviews-slider-right-arrow">
                <FontAwesomeIcon icon="angle-right" onClick={() => slider.slickNext()} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

Reviews.propTypes = {
  loadReviews: func.isRequired,
  reviews: array,
  text: object,
};

export default Reviews;
