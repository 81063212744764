// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './TariffTableCol.css';

import { Icon, Popover } from 'antd';
import { func, number, object, string } from 'prop-types';
import React from 'react';
import ym from 'react-yandex-metrika';

const colors = ['green', 'orange', 'purple', 'black'];

const metrikaGoals = {
  start: 'buystart',
  advanced: 'buyadvanced',
  expert: 'buyexpert',
  rar: 'buyrar',
};

const TariffTableCol = React.memo(
  ({ index, tariffData, term, modalToggle, toggleSpecialOfferDescriptionModal }) => {
    const splitNumber = number =>
      number.length > 3
        ? number
            .toString()
            .split('')
            .reverse()
            .join('')
            .replace(/(.{3})/g, '$1 ')
            .split('')
            .reverse()
            .join('')
        : number;

    const generatePriceContent = (price, term) => {
      let monthly = null;
      let month = null;
      let specialOffer = null;

      if (term !== 'unlim' && tariffData.name === 'Rare') {
        specialOffer = true;
      }

      if (term !== 'm1' && term[0] === 'm') {
        monthly = Math.floor(price / +term.slice(1));
        month = tariffData.price.m1;
      }

      return (
        <div className="rates-table-price-cont">
          {specialOffer && (
            <span className="rates-table-specialOffer">
              спец.&nbsp;предложение
              <span className="rates-table-specialOffer-price">
                {`500₽${!month ? '' : ' / мес.'}`}&nbsp;
                <Popover
                  content="Специальное предложение, доступное только для новых пользователей в течении трёх дней после регистрации."
                  overlayClassName="rates-table-popover"
                  getPopupContainer={trigger => trigger.parentNode}
                >
                  <Icon type="question-circle" className="rates-table-col-special-warning-icon" />
                </Popover>
              </span>
            </span>
          )}

          {monthly && (
            <span className="rates-table-price-monthly">
              {splitNumber(monthly)}₽&nbsp;/&nbsp;мес.
            </span>
          )}
          <span
            className={monthly ? 'rates-table-price' : 'rates-table-price-monthly'}
            style={
              specialOffer && !month
                ? {
                    textDecoration: 'line-through',
                    textDecorationColor: '#f17775',
                  }
                : null
            }
          >
            {splitNumber(price)}₽
          </span>
        </div>
      );
    };

    /* const generateSwapContent = (tariffData, term) => {
      if (tariffData.name === 'Rare') return 'не требуется';

      if (term !== 'unlim' && (!tariffData.moduleSwap || !tariffData.moduleSwap[term]))
        return 'платно';

      if (term === 'unlim') {
        return tariffData.moduleSwap && tariffData.moduleSwap.m12
          ? tariffData.moduleSwap.m12 * 3
          : 'платно';
      }

      return tariffData.moduleSwap[term];
    }; */

    return (
      <div className={`rates-table-col rates-table-col-${colors[index]}`}>
        <div className="rates-table-col-header">
          <h4>{tariffData.name}</h4>
          <span>{tariffData.name2}</span>
        </div>
        <div className="rates-table-col-body">
          <div className="rates-table-col-cell-wrapper">
            <div className="rates-table-col-cell-content">
              {tariffData.primaryModules === 'all' ? (
                'Все'
              ) : (
                <span onClick={() => modalToggle(true, 'primary')} className="modules-desc-link">
                  {tariffData.primaryModules} на выбор
                </span>
              )}
            </div>
          </div>
          {/* <div className="rates-table-col-cell-wrapper">
            <div className="rates-table-col-cell-content">
              {tariffData.secondaryModules === 'all' ? (
                'Все'
              ) : (
                <span onClick={() => modalToggle(true, 'secondary')} className="modules-desc-link">
                  {tariffData.secondaryModules} на выбор
                </span>
              )}
            </div>
          </div>
          <div className="rates-table-col-cell-wrapper">
            <div className="rates-table-col-cell-content">{tariffData.accountsNumber}</div>
          </div>
          <div className="rates-table-col-cell-wrapper">
            <div className="rates-table-col-cell-content">
              {generateSwapContent(tariffData, term)}
            </div>
          </div>
          <div className="rates-table-col-cell-wrapper">
            <div className="rates-table-col-cell-content">
              <span className="rates-table-col-soc-icons">
                {(tariffData.soc.vk || tariffData.soc.all) && (
                  <FontAwesomeIcon icon={['fab', 'vk']} />
                )}
                {(tariffData.soc.fb || tariffData.soc.all) && (
                  <FontAwesomeIcon
                    icon={['fab', 'facebook']}
                    className="rates-table-col-soc-under-development"
                  />
                )}
                {(tariffData.soc.ok || tariffData.soc.all) && (
                  <FontAwesomeIcon
                    icon={['fab', 'odnoklassniki-square']}
                    className="rates-table-col-soc-under-development"
                  />
                )}
                {(tariffData.soc.inst || tariffData.soc.all) && (
                  <FontAwesomeIcon
                    icon={['fab', 'instagram']}
                    className="rates-table-col-soc-under-development"
                  />
                )}
                {(tariffData.soc.fb || tariffData.soc.all) && (
                  <Popover
                    overlayClassName="rates-table-popover"
                    getPopupContainer={trigger => trigger.parentNode}
                    content={
                      <div style={{ maxWidth: 300 }}>
                        На данный момент, все функции работают только для ВК. Для других соц. сетей
                        доступно менее 10% функционала. Ведутся разработки.
                      </div>
                    }
                  >
                    <Icon type="exclamation-circle" className="rates-table-col-soc-warning" />
                  </Popover>
                )}
              </span>
            </div>
          </div>
          {/*<div className="rates-table-col-cell-wrapper">
          <div
            className={`rates-table-col-cell-content ${
              tariffData.privileges.indexOf('до') > -1 ? 'privileges-gray' : ''
            }`}
          >
            {tariffData.privileges}
          </div>
          </div> */}
          <div className="rates-table-col-cell-wrapper rates-table-col-cell-price">
            <div className="rates-table-col-cell-content">
              {generatePriceContent(tariffData.price[term], term)}
            </div>
          </div>
          <div className="rates-table-col-cell-wrapper rates-table-col-cell-activate-btn">
            {tariffData.alias !== 'rar' ? (
              <a
                href="/lk/shop"
                className="ssmm-btn ssmm-btn-green"
                onClick={() =>
                  metrikaGoals[tariffData.alias]
                    ? ym('reachGoal', metrikaGoals[tariffData.alias])
                    : null
                }
              >
                <span>Активировать</span>
              </a>
            ) : (
              <button
                className="ssmm-btn ssmm-btn-green"
                onClick={() => {
                  metrikaGoals[tariffData.alias] && ym('reachGoal', metrikaGoals[tariffData.alias]);
                  toggleSpecialOfferDescriptionModal();
                }}
              >
                <span>Активировать</span>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
);

TariffTableCol.propTypes = {
  tariffData: object.isRequired,
  term: string.isRequired,
  index: number.isRequired,
  modalToggle: func.isRequired,
  toggleSpecialOfferDescriptionModal: func.isRequired,
};

export default TariffTableCol;
