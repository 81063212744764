import 'firebase/storage';

import firebase from 'firebase/app';

import documents from '../../../config/documents';

export const pdfModalOpen = documentName => async (dispatch, getState) => {
  let url = getState().documents[documentName];

  if (!url) {
    url = await firebase.storage().ref(documents[documentName].ref).getDownloadURL();

    dispatch({ type: 'DOCUMENT_URL_RES', url, documentName });
  }

  dispatch({
    type: 'PDF_MODAL_OPEN',
    url,
    title: documents[documentName].title,
  });
};

export const pdfModalClose = () => ({ type: 'PDF_MODAL_CLOSE' });
