import './FinalTest.css';

import React from 'react';
import ym from 'react-yandex-metrika';

const FinalTest = React.memo(() => (
  <section className="final">
    <div className="container">
      <div className="section-header">
        <h1>Лучшее предложение для нового пользователя</h1>
      </div>

      <div className="final-body">
        <div className="final-left">
          <div className="final-left-text-wrap">
            <h2>Только для новых пользователей</h2>
            <p>Доступ к любому модулю сервиса со скидкой 60%</p>
            <span className="final-left-price">500 руб.</span>
          </div>
          <a
            className="ssmm-btn ssmm-btn-green ssmm-btn-large"
            target="_blank"
            href="/lk"
            title="Регистрация"
            onClick={() => ym('reachGoal', '7_special')}
          >
            <span>Активировать бонус</span>
          </a>
        </div>
        <div className="final-right">
          <p>Отличная возможность попробовать все функции сервиса и выбрать для себя подходящие.</p>
          <p>
            Купить тариф "Раритетный" по цене тарифа "Эксперт" со скидкой 60%. Данное предложение
            будет действовать только при первой покупке. Вы сможете купить на 1, 6 или 12 месяцев
            сразу.
          </p>
          <p>
            Для покупки просто зарегистрируйтесь в системе и в течение 3х дней предложение будет
            доступно в личном кабинете или через расширение в разделе "Покупка и активация".
          </p>
        </div>
      </div>
    </div>
  </section>
));

export default FinalTest;
