const initialState = {
  main: false,
};

export default function loaders(state = initialState, action) {
  switch (action.type) {
    case 'LOADERS_RESET':
      return initialState;

    case 'LOADERS_UPDATE':
      return {
        ...state,
        ...action.loaders,
      };

    default:
      return state;
  }
}
